.add-event-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5ede6; /* Background with theme color */
    padding: 20px;
}

.add-event-form {
    background-color: white;
    padding: 40px; /* Adjusted for consistency */
    border-radius: 10px; /* Match rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Consistent shadow */
    width: 100%;
    min-width: 40vw;
    max-width: 400px; /* Consistent width */
}

.add-event-form-title {
    text-align: center; /* Centered title */
    margin-bottom: 20px;
    color: #c9a236; /* Using the gold color from the theme */
    font-size: 24px; /* Maintain original size */
}

label {
    display: block; /* Block display for labels */
    margin-bottom: 8px;
    font-weight: bold;
    color: #333; /* Optional: darker color for labels */
}

.add-event-input, .add-event-textarea {
    width: 100%; /* Full width for input fields */
    padding: 10px; /* Consistent padding */
    margin-bottom: 20px; /* Adjusted spacing */
    border: 1px solid #ddd; /* Lighter border for input fields */
    border-radius: 5px; /* Rounded corners */
    box-sizing: border-box; /* Ensures padding is included in width */
    resize: vertical; /* Allow vertical resizing only */
    overflow: auto;
}

.add-event-product-selection {
    margin-bottom: 15px; /* Consistent spacing */
}

.add-event-product-item {
    display: flex; /* Align items horizontally */
    align-items: center; /* Center vertically */
}

.add-event-submit-button {
    width: 100%; /* Full width for the submit button */
    padding: 10px;
    background-color: #c9a236; /* Using the gold color from the theme */
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px; /* Consistent font size */
    cursor: pointer;
}

.add-event-submit-button:hover {
    background-color: #f5ede6; /* Background color on hover */
    color: black; /* Text color on hover */
}

.error-message {
    color: #f44336; /* Red color for error messages */
    margin-bottom: 10px;
    text-align: center;
}
.add-event-form-title {
    text-align: center; /* Centered title */
    margin: 0 0 20px; /* Set margin-top to 0 to keep it at the top */
    color: #c9a236; /* Using the gold color from the theme */
    font-size: 24px; /* Maintain original size */
}

.add-event-product-selection {
    margin-bottom: 15px;
}

.selected-product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5ede6; /* Optional background */
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.remove-product-button {
    background-color: transparent;
    border: none;
    color: #f44336; /* Red color for remove button */
    cursor: pointer;
}

.add-event-image-input {
    margin-bottom: 15px;
    padding: 10px; /* Optional padding */
    border: 1px solid #ccc; /* Border similar to other inputs */
    border-radius: 5px; /* Match with other form elements */
    width: 100%; /* Make it full-width */
    cursor: pointer; /* Change cursor to indicate clickability */
    background-color: #fff; /* Background color */
  }
  
  .add-event-image-input::file-selector-button {
    padding: 10px;
    background-color: #C9A236; /* Your gold color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Round corners */
    cursor: pointer; /* Indicate it's clickable */
  }
  
  .add-event-image-input::file-selector-button:hover {
    background-color: #B49327; /* Slightly darker shade on hover */
  }
  
  .error-message {
    color: red;
    background-color: #ffe0e0; /* Light red background */
    border: 1px solid #ffb2b2; /* Darker red border */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  

/* Container for the image previews */
.image-previews {
    display: flex;
    flex-wrap: wrap; /* Allows images to wrap to the next line */
    gap: 10px; /* Space between images */
    margin-top: 10px; /* Space above the image preview section */
  }
  
  /* Style for each individual image preview */
  .image-preview {
    position: relative; /* Allows positioning of the remove button */
    width: 100px; /* Fixed width for image preview */
    height: 100px; /* Fixed height for image preview */
    overflow: hidden; /* Ensures overflow is hidden */
    border: 2px solid #C9A236; /* Gold border around the preview */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  
  /* Style for the preview image */
  .image-preview-thumbnail {
    width: 100%; /* Make the image fill the container */
    height: 100%; /* Make the image fill the container */
    object-fit: cover; /* Ensure the image covers the container */
  }
/* Style for the remove button */
.add-event-remove-image-button {
  position: absolute; /* Position the button over the image */
  top: 5px; /* Adjust top position */
  right: 5px; /* Adjust right position */
  background-color: #ff4d4d; /* Red background for remove button */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  width: 20px; /* Width of the button */
  height: 20px; /* Height of the button */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex; /* Center the icon */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.add-event-remove-image-button:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}

.modify-event-form-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 600px) {
    .add-event-form {
        padding: 20px; /* Reduce padding for smaller screens */
        min-width: 90vw; /* Ensure it takes more width on smaller screens */
    }

    .image-preview {
        width: 80px; /* Adjust width for smaller screens */
        height: 80px; /* Adjust height for smaller screens */
    }

    .add-event-form-title {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .add-event-submit-button {
        font-size: 14px; /* Adjust button font size */
    }
}