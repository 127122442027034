.new-collection-container {
    text-align: center;
    padding: 0 16vw; /* Padding for larger screens */
    padding-bottom: 50px;
}

.new-collection-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-decoration: underline; 
    text-decoration-color: #C9A236; 
    text-underline-offset: 0.3em; 
    letter-spacing: 0.05rem; 
}

.collection-row {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap */
    justify-content: center; /* Center the items */
}

.collection-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1 45%; /* Grow to fill space, shrink if needed, base width of 45% */
    max-width: 48%; /* Ensure columns don't exceed this width */
    box-sizing: border-box; 
    margin: 10px; /* Add margin for spacing */
}

.large-image {
    width: 100%; /* Full width to fit the column */
    height: auto; /* Auto height for responsiveness */
}

.large-image img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    transition: transform 0.3s ease;
}

.large-image,
.small-image-with-text {
    overflow: hidden; 
}

.small-image-with-text {
    display: flex;
    align-items: center;
    width: 100%; /* Full width to fit the column */
    height: 30vh; /* Auto height for responsiveness */
}

.small-image-with-text img {
    width: 50%; /* Half width of the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; 
    transition: transform 0.5s ease;
}



.text-right,
.text-left {
    width: 50%;
    font-family: 'Times New Roman', Times, serif; 
    letter-spacing: 0.02em; 
    line-height: 1.2; 
    margin: 0;
    height: 100%;
    font-size: 1.6rem;
    color: dimgrey;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0px;
}

.text-left {
    width: 50%;
    font-family: 'Times New Roman', Times, serif; 
    letter-spacing: 0.02em; 
    line-height: 1.2; 
    margin: 0;
    height: 100%;
    font-size: 1.6rem;
    color: dimgrey;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 0px;
}
.text-right {
    padding-left: 20px;
    text-align: left;
}

.text-left {
    padding-right: 20px;
    text-align: left;
}

.newCollectbutton {
    text-decoration: none;
    width: 90%;
    padding: 15px 20px;
    font-size: 1.1rem;
    letter-spacing: 0.05em; 
    background-color: transparent;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    
}

.newCollectbutton:hover {
    background-color: black;
    color: white;
}

.large-image img:hover {
    transform: scale(1.1) rotate(2deg); 
}

.small-image-with-text img:hover {
    transform: scale(1.1) rotate(-2deg); 
}

/* Media query for medium screens */
@media (max-width: 1024px) {
    .collection-column {
        max-width: 90%; /* Make it wider on medium screens */
        flex: 1 1 90%; /* Allow the column to take up more space */
    }

    .large-image img {
        width: 100%; /* Maintain full width for large images */
        height: auto; /* Maintain aspect ratio */
    }

    .small-image-with-text img {
        width: 70%; /* Adjust width for small images */
        height: auto; /* Maintain aspect ratio */
    }
}

/* Media query for mobile/small screens */
@media (max-width: 768px) {
    .new-collection-container {
        padding: 0; /* Remove left/right padding */
    }

    .collection-row {
        flex-direction: column; /* Stack the columns vertically */
        align-items: center; /* Center items horizontally */
    }

    .collection-column {
        width: 100%; /* Make columns full width on small screens */
        margin: 0; /* Remove margins */
    }

    .large-image {
        width: 100%; /* Slightly smaller on small screens */
        height: auto;
        margin: auto;
    }

    .large-image img {
        width: 95%; /* Slightly smaller width for large images */
        height: auto;
        margin: auto;
    }

    .small-image-with-text img {
        width: 55%; /* Adjust small image width for mobile */
        height: auto;
        padding-top: 20px;
    }

    .text-left, .text-right {
        padding: 5px; /* Remove padding for text on small screens */
        font-size: 19px;
        line-height: 1.0;
        height: 20vh;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .text-left {
        padding: 15px 5px;
    }
    .newCollectbutton{
        
        text-decoration: none;
        padding-top: 15px;
        margin-top: -19px;
    }
}