
/* Ensure all elements use border-box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: row;
}

.carousel-container {
  display: flex;
  flex-direction: column; /* Ensure the carousel container is a column */
  position: relative; /* Allow absolute positioning of children */
  max-width: 100vw;
  background-color: #f5ede6; /* Background color */
  padding: 0px 20px;
  min-height: 70vh; 
  overflow: hidden; 
}

.carousel-slide {
  display: flex;
  overflow: hidden; /* Prevents overflow of slides */
  height: 70vh; /* Ensures content aligns properly in full height */
  margin: 0 100px;
  max-width: 80vw;
}

.slides {
  display: flex; /* Align slides in a row */
  transition: transform 0.5s ease; /* Smooth sliding effect */
  max-width: 80vw;
  max-height: 100%;
}

.slide {
  min-width: 80vw; /* Each slide takes full width */
  display: flex;
  align-items: center; /* Center aligns content vertically */
  justify-content: space-between; /* Ensures space is distributed */
  overflow: hidden;
}

/* Content styling */
.carousel-content {
  text-align: left; /* Align text to the left */
  color: black; /* Change to black for better visibility against light background */
  min-width: 60%; /* Prevents content from expanding too wide */
  flex:5;
  margin-right: auto;
}

.carousel-image {
  height: 100%; /* Ensure the image scales nicely */
  max-width: 42%; /* Make sure it does not overflow */
  object-fit: cover; /* Cover the container's area, cropping if necessary */
  object-position: center;
  flex:1;
  margin-left: auto;
}


/* Title style - Big Text */
.big-text {
  font-size: 3.2rem; /* Larger title size */
  font-weight: 700;
  margin-top: 10px;
  font-style: none;
  letter-spacing: 2px; /* Increased spacing for event-like feel */
  line-height: 1.2;
  font-family: 'Playfair Display','Montserrat', sans-serif; /* Elegant font */
  color: black; /* Gold color */
  text-align: left; /* Center-align the title */
  text-transform: uppercase; /* Make title more formal */
}

/* Slogan style - Medium Text */
.medium-text {
  color: #c9a236; /* Gold color */
  font-size: 1.8rem; /* Slightly smaller size */
  margin-top: 20px;
  letter-spacing: 1px; /* Slightly wider spacing */
  line-height: 1.4;
  font-family: 'Playfair Display','Roboto', sans-serif; /* Cursive font for the slogan */
  font-style: italic; /* Cursive style for a handwritten feel */
  font-weight: 400;
  text-align: left; /* Center-align the slogan */
  opacity: 0.8; /* Slight opacity to differentiate from the title */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
}


/* Button styles */
.cta-button {
  margin-top: 30px;
  padding: 15px 20px; /* Adjust padding */
  font-size: 1rem; /* Responsive font size */
  border: 1px solid black;
  background-color: transparent; /* Gold-like color */
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease, padding 0.3s ease; /* Add padding transition */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center align items */
}

.arrow {
  display: none; /* Hide the arrow initially */
  margin-left: 8px; /* Add space between text and arrow */
  transition: opacity 0.3s ease; /* Smooth opacity transition */
}

.cta-button:hover {
  font-size: 0.95rem; /* Slightly smaller on hover */
  background-color: #E8D6B1; /* Darker gold on hover */
  padding-left: 5px; /* Reduce left padding on hover */
}

.cta-button:hover .arrow {
  display: inline; /* Show the arrow only on hover */
  opacity: 1; /* Ensure the arrow is visible */
}

.cta-button .arrow {
  opacity: 0; /* Hide the arrow initially */
}

/* Arrow styles */
.next-arrow,
.prev-arrow {
  width: 50px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  background: white;
  border: 1px solid gray;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

.small-text {
  color: #706B68;
  font-size: 1.5rem;
  margin-top: 20px;
  letter-spacing: 0.8px;
  line-height: 1.4;
  max-width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.small-text {
  font-size: 1.4rem; /* Responsive font size */
  color: #a2a2a2;
  letter-spacing: 0.5px; /* Adjust as needed */
  line-height: 1.2; /* Adjust as needed */
  font-weight: 500;
  /* Limit to 2 lines with ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next-arrow {
  right: 25px; /* Position the next arrow */
}

.prev-arrow {
  left: 25px; /* Position the previous arrow */
}/* Media Queries for Responsiveness *//* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .carousel-container {
    padding: 10px; /* Add padding to prevent overlap with arrows */
    margin: 0; /* Remove margin */
    max-height: 75vh; /* Increase max-height on smaller screens */
    min-height: 75vh; /* Increase min-height on smaller screens */
  }

  .carousel-slide {
    margin: 0; /* Remove margin */
    flex-direction: column; /* Ensure column layout */
    justify-content: space-evenly;
  }

  .slide {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center align items in column */
    justify-content: flex-start;
    max-height: 100%; /* Ensure slides take full height */
    display: flex; /* Ensure flexbox layout */
  }

  .carousel-content {
    max-width: 100%; /* Allow full width for content */
    min-width: 70%;
    overflow-wrap: break-word; /* Enable text wrapping */
    margin:0;
  }

  .carousel-image {
    max-width: 75%; /* Set max-width to 75% of the container */
    height:auto;
    display: block; /* Ensure it behaves like a block element for centering */
    margin: 0 auto; /* Center the image */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
  }

  .small-text {
    display: none; /* Hide small and medium text on small screens */
  }

  .big-text {
    font-size: 2.5rem; /* Adjust font size */
  }

  .cta-button {
    padding: 10px 15px; /* Smaller padding */
    font-size: 0.9rem; /* Smaller font size */
    margin: 0 auto; /* Center the button */
    margin-bottom: 5px;
  }

  .next-arrow,
  .prev-arrow {
    width: 40px; /* Smaller arrows */
    height: 40px; /* Smaller arrows */
  }
}

@media (max-width: 768px) {
  .carousel-container {
    padding: 10px; /* Add padding to prevent overlap with arrows */
    margin: 0; /* Remove margin */
    max-height: 75vh; /* Increase max-height on smaller screens */
    min-height: 75vh; /* Increase min-height on smaller screens */
  }
  .slides {
    display: flex; /* Align slides in a row */
    transition: transform 0.5s ease; /* Smooth sliding effect */
    max-width: 80vw;
    max-height: 65%;
  }
  .carousel-slide {
    margin: auto; /* Remove margin */
    flex-direction: column; /* Ensure column layout */
  }
  .slide {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center align items in column */
    justify-content: flex-start;
    max-height: 100%; /* Ensure slides take full height */
    display: flex; /* Ensure flexbox layout */
  }
  

  .carousel-content {
    max-width: 100%; /* Allow full width for content */
    min-width: 70%;
    overflow-wrap: break-word; /* Enable text wrapping */
  }

  .carousel-image {
    max-width: 75%; /* Set max-width to 75% of the container */
    max-height: 60%; /* Set a fixed height relative to the container */
    display: block; /* Ensure it behaves like a block element for centering */
    margin: 0 auto; /* Center the image */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
  }

  .small-text {
    display: none; /* Hide small and medium text on small screens */
    text-align: center;
  }

  .big-text {
    font-size: 1.5rem; /* Adjust font size */
    text-align: center;
    white-space: nowrap; /* Prevent wrapping to a new line */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis if text is too long */
    display: block; /* Ensure proper box model */
    width: 100%; 
  }
  .medium-text {
    text-align: center;
  }
  .medium-text {
    white-space: nowrap; /* Prevent wrapping to a new line */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis if text is too long */
    display: block; /* Ensure proper box model */
    width: 100%; /* Ensure it fits the container width */
  }
  

  .cta-button {
    padding: 10px 15px; /* Smaller padding */
    font-size: 0.9rem; /* Smaller font size */
    margin: 0 auto; /* Center the button */
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .next-arrow,
  .prev-arrow {
    width: 40px; /* Smaller arrows */
    height: 40px; /* Smaller arrows */
  }
}

@media (max-width: 480px) {
  .carousel-container {
    max-height: 55vh; /* Further increase max-height on very small screens */
    min-height: 55vh; /* Further increase min-height on very small screens */
    padding: 5px; /* Add padding to prevent overlap with arrows */

  }

  .carousel-image {
    max-width: 80%; /* Full width on smaller screens */
    display: block; /* Ensure it behaves like a block element for centering */
    margin: 0 auto; /* Center the image */
    height: auto; /* Maintain aspect ratio */
  }

  .slide {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center align items in column */
    justify-content: flex-start;
    height: 100%; /* Ensure slides take full height */
    display: flex; /* Ensure flexbox layout */

  }

  .big-text {
    font-size: 1.6rem; /* Smaller font size */
    overflow-wrap: break-word; /* Enable text wrapping for big text */
    text-align: center;
  }
  .medium-text {
    font-size: 1.5rem; /* Smaller font size */
    overflow-wrap: break-word; /* Enable text wrapping for big text */
    text-align: center;
  }
  /* Arrow styles */
.next-arrow{
  transform: translateX(25%); /* Center vertically */
}

.prev-arrow {
  transform: translateX(-25%); /* Center vertically */
}
}

