.form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-width: 40vw;
    max-width: 75vw;
    min-height: 94vh;
  }
  
  .form-content {
    width: 50%;
  }
  
  .product-review-section {
    width: 40%;
    padding-left: 20px;
  }
  
  .form-section {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold; /* Make labels bold */
  }
  
  .input-field,
  .textarea-field,
  .file-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 2px; /* Add border-radius */
    border: 1px solid #ccc; /* Add border for input fields */
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #C9A236; /* Gold color for submit button */
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .submit-button:hover {
    background-color: #b18f2e; /* Darker shade on hover */
  }
  
  h2 {
    color: #C9A236; /* Gold color for headings */
  }
  
  .image-preview ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .image-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  
  .suggestions-list {
    list-style-type: none;
    padding: 0;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .no-suggestions {
    padding: 10px;
    color: #888;
  }
  