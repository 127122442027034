.product {
    position: relative;
    height: 58vh;
    padding: 0;
    text-align: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 20vw;
}

.product-container {
    position: relative; /* Ensure absolute positioning works within the container */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: background-color 0.3s ease;
    border: 1px solid lightgray;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.productInfo{
    margin: 15px 0px;
}

.product-container::before,
.product-container::after {
    content: "";
    position: absolute;
    border: 1px solid #C9A236; /* Border color for animation */
    pointer-events: none; /* Prevent interaction */
    opacity: 0; /* Start with invisible borders */
    transition: all 5s ease; /* Smooth transition */
}

.product-container::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0); /* Start scaled down */
    transform-origin: top left; /* Origin for scaling */
}

.product-container::after {
    bottom: 0;
    right: 0;
}

.product:hover .product-container::before {
    animation: draw-border 2s forwards; /* Animate drawing the border */
    opacity: 1; /* Make borders visible */
}

.product:hover .product-container {
    background-color: #ffffff; /* Change background to white on hover */
}

.productIcon {
    min-width: 100%;
    min-height: 80%; /* Image occupies 70% of the height */
    max-width: 100%;
    max-height: 80%; 
    fill: #C9A236; /* Apply gold fill to the SVG */
    margin: auto;
    transition: fill 0.3s ease; /* Transition for fill color */
}

.product:hover .productIcon {
    fill: #000; /* Change icon color to black on hover */
}

.product-title {
    flex: 1; /* Allow title to take available space */
    height: 20%; /* Title occupies 20% of the height */
    color: #333;
    font-size: 0.9rem;
    text-decoration-color: #C9A236;
    text-underline-offset: 0.3em;
    letter-spacing: 0.05rem;
    transition: color 0.3s ease;
    text-overflow: ellipsis; /* Add ellipsis if text is too long */
    white-space: wrap; /* Prevent text from wrapping */
}

.product:hover .product-title {
    color: #C9A236; /* Change font color to #C9A236 on hover */
}

.priceButton {
    height: 10%; /* Button occupies 10% of the height */
    background-color: white;
    color: black;
    font-weight: bolder;
    font-size: 12px; /* Small text */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    display: inline-flex;
    align-items: center;
    justify-content: center; /* Center the button content */
}

.priceButton::after {
    content: ' ➔'; /* Arrow always visible */
    margin-left: 8px;
}

    .eye-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent; /* Start with transparent */
        border-radius: 50%; /* Make it circular */
        padding: 5px; /* Add some padding */
        cursor: pointer;
        opacity: 0; /* Initially hidden */
        transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition for background and opacity */
        color: black; /* Icon color */
        z-index: 10; /* Ensure it appears above the image */
    }
    
    .product:hover .eye-icon {
        opacity: 1; /* Show the icon on hover */
    }
    .eye-icon:hover {
        background-color: rgba(128, 128, 128, 0.5); /* Gray background on hover */
        opacity: 1; /* Show the icon on hover */
    }

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #C9A236;
    z-index: 1000; /* Ensure the popup is on top */
    width: 300px;
    text-align: center;
}

.popup-content {
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #333;
}

.view-more {
    font-weight: bold;
    font-size: 12px; /* Small text */
    text-decoration: none;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 7px
}

.view-more:hover {
    text-decoration: underline;
}

  /* The confirmation message box */
  .confirmation-message {
    background-color: white;
    color: #147263;
    border: 2px solid #147263;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the element */
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Confirmation message text */
  .confirmation-text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bolder;
  }
  
  
@keyframes draw-border {
    0% {
        transform: scale(0); /* Start from scale 0 */
        opacity: 1; /* Make visible from the start */
    }
    25% {
        transform: scaleX(1); /* Draw top border */
    }
    50% {
        transform: scaleY(1); /* Draw left border */
    }
    75% {
        transform: scaleY(1); /* Draw right border */
        transform-origin: bottom right; /* Change origin for scaling */
    }
    100% {
        transform: scale(1); /* Complete the rectangle */
    }
}
@media (max-width: 991px) and (min-width: 768px) {
    .product{
        max-width: 40vw;
    }
  }
@media (max-width: 767px) and (min-width: 501px){
    .product{
        min-width: 60vw;
        min-height: 40vh;
        max-width: 60vw;
        max-height: 50vh;
        overflow: hidden;
    }
  }

  @media (max-width: 500px) {
    .product{
        min-width: 70vw;
        min-height: 40vh;
        max-width: 70vw;
        max-height: 45vh;
        overflow: hidden;
    }
  }
