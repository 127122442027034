.admin-modify-products-container {
    padding: 20px;
    padding-bottom: 0;
    max-height: 100vh;
}

.admin-category-filter, .admin-search-bar {
    padding: 12px; /* Add padding for better appearance */
    margin-bottom: 20px; /* Space between elements */
    border-radius: 8px; /* Rounded corners for a modern look */
    border: 1px solid #c9a236; /* Border matching your theme */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-size: 16px; /* Make text slightly larger */
    color: #333; /* Text color */
    background-color: #f9f9f9; /* Light background */
    transition: all 0.3s ease; /* Smooth transition on hover/focus */
    
}
.filterAndSearch {
    display: flex;
    justify-content: space-between; /* Adds space between the items */
    align-items: center;
    gap: 20px; /* Controls the gap between elements */
    margin-bottom: 20px; /* Space below the filter/search section */
  }

.admin-category-filter {
    appearance: none; /* Remove default arrow for dropdown */
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMUwwLjY5OCAyLjMwN2wtLjcwNi42OTdMNiA4bDYuMDcyLTYuMDk1LS42ODYtLjc0MkwwIDEgWiIgZmlsbD0iI2M5YTIzNiIvPjwvc3ZnPg==') no-repeat right 10px center; /* Custom arrow icon */
    background-size: 10px; /* Adjust size of arrow */
}

.admin-category-filter:hover, .admin-search-bar:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
    background-color: #fff; /* Brighten background on hover */
}

.admin-category-filter:focus, .admin-search-bar:focus {
    outline: none; /* Remove default outline */
    border: 1px solid #c9a236; /* Highlight border on focus */
    box-shadow: 0 0 5px rgba(201, 162, 54, 0.6); /* Glow effect for focus */
}



.admin-product-list {
    max-height: 76vh; /* Set a max height for scrollable area */
    overflow-y: auto; /* Make it scrollable */
    display: flex;
    flex-direction: column;
}

.admin-product-bar {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-direction: column;
}

.admin-product-details {
    display: flex;
    align-items: flex-start; /* Align items vertically */
    margin-bottom: 20px;
    margin-right: 50px;
    flex-direction: row;
    width: 70%;
}

.admin-product-image {
    flex:1;
    width: 15%;
    height: 15%;
    object-fit: cover;
    margin-right: 15px; /* Space between image and text */
   
}

.admin-product-info {
    flex-grow: 1; /* Make the info section take available space */
    text-align: left;
    flex:8;
}

.admin-product-actions {
    margin-left: 10px; /* Space for action buttons */
}

.admin-edit-btn, .admin-delete-btn, .admin-save-btn, .admin-cancel-btn, .admin-cancel-delete-btn {
    margin-right: 20px; /* Space between buttons */
    padding: 8px 12px; /* Padding for buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: normal;
    min-width: 60px;
}

.admin-edit-btn, .admin-cancel-delete-btn {
    background-color: #c9a236; /* Green background */
    color: white;
}

.admin-edit-btn:hover, .admin-cancel-delete-btn:hover {
    background-color: #f5ede6; /* Green background */
    color: black;
}

.admin-delete-btn {
    background-color: #f44336; /* Red background */
    color: white;
}

.admin-delete-btn:hover {
    background-color: #ed7c74; /* Red background */
    color: black;
}

.admin-save-btn {
    background-color: #c9a236; /* Green background */
    color: white;
}

.admin-cancel-btn {
    background-color: #f44336; /* Orange background */
    color: white;
}

.admin-save-btn:hover {
    background-color: #f5ede6; /* Green background */
    color: black;
}

.admin-cancel-btn:hover {
    background-color: #ed7c74; /* Red background */
    color: black;
}
#admin-edit-product-form {
    margin-top: 20px;
    text-align: left; /* Align the form's text */
}

.admin-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.admin-modal-content {
    background-color: #fff; /* Background color for the modal content */
    border-radius: 8px; /* Optional: Add border radius for aesthetics */
    width: 90%; /* Set a width for the modal */
    max-width: 600px; /* Maximum width for larger screens */
    max-height: 80%; /* Maximum height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px; /* Add padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}




.admin-confirm-delete-btn {
    margin-right: 20px; /* Space between buttons */
    padding: 8px 12px; /* Padding for buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: normal;
    min-width: 60px;
    background-color: #f44336; /* Red background */
    color: white;
}

.admin-confirm-delete-btn:hover {
    background-color: #ed7c74; /* Red background */
    color: black;
}

/* Modal specific styling for alignment */
.admin-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    text-align: left;
}

#admin-edit-product-form {
    margin-top: 20px;
    text-align: left;
}

.admin-modal-content label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.admin-modal-content input[type="text"], 
.admin-modal-content input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.image-preview {
    display: block;
    margin-top: 10px;
    max-width: 100%; /* Make sure the image fits within the form */
    max-height: 200px; /* Limit the image height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
    border: 1px solid #ddd;
    padding: 5px;
    margin-bottom: 20px;
}

/* Center buttons */
.admin-modal-content .admin-save-btn,
.admin-modal-content .admin-cancel-btn {
    display: inline-block;
    width: 48%; /* Buttons take equal width */
    text-align: center;
    margin: 0 1%; /* Small space between buttons */
    padding: 10px 0;
}

.admin-modal-actions {
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 20px;
}

.admin-modal-content .admin-confirm-delete-btn,
.admin-modal-content .admin-cancel-delete-btn {
    width: 45%; /* Set equal width for both buttons */
    text-align: center;
}

/* Modify product list alignment */
.admin-product-bar {
    text-align: left;
}




.modal-split-layout {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.product-images-slider {
    flex: 1;
}

.admin-image-slider {
    display: flex;
    overflow-x: scroll;
    gap: 10px;
}

.slider-item {
    position: relative;
}

.slider-image {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.delete-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.category-dropdown {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
}

.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.admin-save-btn, .admin-cancel-btn {
    padding: 10px 20px;
    cursor: pointer;
}




/* Slider container */
.product-images-slider {
    flex:1;
    max-width: 50%;
}

.admin-image-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.slider-item {
    min-width: 200px; /* Adjust this width as necessary */
    scroll-snap-align: start;
    margin-right: 10px;
    position: relative;
}

.slider-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

.delete-image-btn {
    position: absolute;
    left: 50%;
    top:80%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.delete-image-btn:hover {
    background-color: rgba(255, 0, 0, 0.9);
}

/* Horizontal scrollbar styling */
.admin-image-slider::-webkit-scrollbar {
    height: 8px;
}

.admin-image-slider::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.admin-image-slider::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.admin-image-slider::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.image-preview-container{
    display: flex;
    max-width: 200%;
    overflow-x: scroll;
}
.toggle-container {
    display: flex;
    align-items: center; /* Vertically center the items */
    gap: 20px; /* Space between items */
    margin-bottom: 15px; /* Space below the container */
  }
  
  .toggle-item {
    display: flex;
    align-items: center; /* Vertically center the label and button */
    gap: 8px; /* Space between label and button */
  }
  
  .toggle-item label {
    font-weight: bold; /* Make the label bold */
  }
  
  .toggle-item button {
    padding: 8px 12px; /* Add padding for a better button size */
    background-color: #C9A236; /* Match your theme color */
    color: white; /* Text color for the button */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .toggle-item button:hover {
    background-color: #a68c29; /* Darken color on hover */
  }
  
  .filterAndSearch {
  display: flex;
  justify-content: space-between; /* Adds space between the items */
  align-items: center;
  gap: 20px; /* Controls the gap between elements */
  margin-bottom: 20px; /* Space below the filter/search section */
}

.search-container {
  flex: 1; /* Makes the search bar grow and take available space */
  max-width: 500px;
  display: flex;
}

.admin-search-bar {
  width: 100%; /* Ensures the search bar stretches fully within its container */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-container {
  flex:1;
  max-width: 500px;
  display: flex;
}

.admin-category-filter {
  width: 100%; /* Ensures the select dropdown takes full width of its container */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-container span,
.filter-container span {
  text-wrap: nowrap;
  padding: 8px; /* Add some space below the span */
}

