@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.container {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  text-align: left;
  line-height: 2;
  flex-wrap: wrap; /* Allow items to wrap when necessary */
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  border-top: 1px solid #ddd;
  background-color: #f8f8f8;
  text-align: left;
  line-height: 2;
  flex-wrap: wrap; /* Allow items to wrap when necessary */
  position: relative; /* Ensure the footer is at the bottom of the page */
  bottom: 0;
  width: 100%; /* Ensure the footer spans the entire width of the page */
  margin: 0; /* Remove any spacing between the footer and the rest of the content */
}


/* Columns for the desktop layout */
.column {
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.column1 {
  flex: 3;
  padding: 20px;
  margin-right: 80px;
}

.column2,
.column3 {
  flex: 2;
}

.column4 {
  flex: 3;
  /* margin-top: auto; */
}

/* Responsive logo */
.column1 img {
  max-width: 100%;
  height: auto;
  width: 200px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links a {
  position: relative;
  color: black;
  text-decoration: none;
  transition: color 0.3s;
  line-height: 1.5;
}

.footer-links a::after {
  content: '';
  display: block;
  height: 2px;
  background: #c9a236;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.footer-links a:hover::after {
  transform: scaleX(1);
}

.footer-links a:hover {
  color: #c9a236;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.social-icons a {
  display: flex;
  font-size: 22px;
  color: black;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  width: 45px;
  height: 45px;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.social-icons a:hover {
  background-color: #c9a236;
  color: white;
}

.contact-icon {
  color: #c9a236;
  margin-right: 15px;
  padding-top: 5px;
  font-size: 20px;
}

.contact-icon-wp {
  color: #c9a236;
  margin-right: 15px;
  padding-top: 5px;
  font-size: 26px;
  width:21px;
}

.footer-contact p {
  display: flex;
  align-items: left; /* Align the icon and text */
  margin: 0px 0; /* Add consistent margin to space out the items */
}

.footer-contact a {
  color: inherit;
  text-decoration: none;
  margin-left: 2px; /* Add space between icon and link text */
}


/* Media Queries for responsiveness */
@media (max-width: 1200px) {

  .main-container{
    padding: 20px 50px;
  }

  .column1 {
    flex: 2;
    margin-right: 40px;
  }

  .column2,
  .column3,
  .column4 {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .main-container {
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
  
  .column {
    width: 100%;
    margin-bottom: 20px;
  }

  .social-icons {
    justify-content: space-between;
  }
  .footer-links {
    padding-left: 25px;
  }
}

@media (max-width: 480px) {
  .main-container {
    padding: 10px;
  }

  .column1 img {
    width: 150px;
  }

  .social-icons a {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}
