.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This will make the sides stretch to the same height */
  background-color: white; /* Theme background color */
  padding: 20px;
  margin: 0 15%;
}

.about-side {
  flex: 1;
  margin: 10px;
  display: flex; /* Add this to make the contents of the side flex */
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between; /* Distribute space evenly */
}

/* Keeping the rest of your styles as they are */
.about-us-container {
  min-height: 400px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.about-us-container p {
  font-family: "Georgia", serif; /* Elegant and readable font */
  font-size: 1.1rem; /* Slightly larger for easy readability */
  line-height: 1.8; /* Increase spacing between lines for clarity */
  color: #4a4a4a; /* Soft gray for a luxurious feel */
  text-align: justify; /* Align text evenly for a polished look */
  margin-bottom: 20px; /* Add spacing after each paragraph */
}

.about-us-container p::first-letter {
  font-weight: bold; /* Highlight the first line for emphasis */
  font-size: 1.2rem; /* Slightly larger first line for impact */
  color: #c9a236;
}

.about-us-container h3 {
  color: #c9a236; /* Gold color */
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.about-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.about-side {
  width: 48%; /* Adjust to fit your layout */
}

.about-us-container {
  position: relative;
  width: 100%;
}

/* Media query for small screens (e.g., phones) */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column; /* Change flex direction to column */
    margin: 0 5%; /* Adjust margin to make it more responsive */
  }

  .about-side {
    width: 100%; /* Ensure the side takes up full width */
    margin: 0; /* Remove margin to allow full width */
  }
}
