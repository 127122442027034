@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
/* styles.css */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c9a236;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a08b2e;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #c9a236 #f1f1f1;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
}
