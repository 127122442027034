.category-slider {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the slider within the container */
  gap: 10px;
  padding: 20px 15px;
  max-width: 1200px; /* Set a max-width for larger screens */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box;
  overflow: hidden;
}
.category-cards {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;

  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

.category-cards::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}


.category-cards::-webkit-scrollbar {
  display: none;
}

.arrow-button {
  width: 30px;
  height: 30px;
  font-size: 18px;
}
.category-container{
  flex:1;
}
@media (min-width: 768px) {
  .category-slider {
    gap: 10px;
    padding: 50px 40px;
    
  }

  .arrow-button {
    width: 40px;
    height: 40px;
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  .category-slider {
    gap: 20px;
    padding: 0;
    margin-right: 5px;
    max-width: 100vw;
  }
  .category-cards {
    padding: 0 5px;
    gap: 8px;
    min-width: 45vw;
  }
}

.body{
  margin:0;
  padding:0;
}
.html{
  margin:0;
  padding:0;
}