.admin-request-container {
    padding: 20px;
    background-color: #f8f8f8; /* Background color for the container */
    border: 1px solid #ddd; /* Optional: border around the container */
  }
  
  .admin-request-title {
    text-align: center;
    color: #C9A236; /* Gold color for the title */
    margin-bottom: 20px;
  }
  
  .admin-request-table {
    width: 100%;
    border-collapse: collapse; /* Collapse borders for cleaner look */
  }
  
  .admin-request-table th, .admin-request-table td {
    border: 1px solid #ddd; /* Table cell border */
    padding: 10px; /* Padding for table cells */
    text-align: left; /* Align text to the left */
  }
  
  .admin-request-status-dropdown {
    padding: 5px;
    width: 100%; /* Full width for dropdown */
  }
  
  /* CSS for truncating the admin notes */
.admin-request-notes {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  text-overflow: ellipsis;
  max-width: 250px; /* Adjust the max width as needed */
}

.admin-request-notes-container {
  display: flex;
  align-items: center;
  max-width: 250px;
}

.admin-request-notes-icon {
  cursor: pointer;
  margin-left: 8px;
  font-size: 1.2rem;
}

  .admin-request-update-button,
  .admin-request-delete-button {
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    margin: 5px;
  }
  
  .admin-request-update-button {
    background-color: #C9A236; /* Gold color for update button */
    color: white; /* Text color */
  }
  
  .admin-request-delete-button {
    background-color: red; /* Red color for delete button */
    color: white; /* Text color */
  }
  
  .admin-request-update-button:hover {
    background-color: darkgoldenrod; /* Darker shade on hover */
  }
  
  .admin-request-delete-button:hover {
    background-color: darkred; /* Darker shade on hover */
  }
  
  /* modal.css */
.requests-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the modal is on top */
  }
  
  .requests-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px; /* Width of the modal */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .requests-modal-actions {
    display: flex;
    justify-content: space-between; /* Space out buttons */
    margin-top: 10px;
  }
  
  .requests-modal-content textarea {
    width: 50%; /* Make it full width */
    min-height: 80px; /* Minimum height */
    resize: none; /* Prevent manual resizing */
    overflow: wrap; /* Show scroll when content overflows */
    
  }
  .admin-request-table td {
    max-width: 200px; /* Set a maximum width to the cell */
    word-wrap: break-word; /* Allow long words to break and wrap */
    overflow: hidden; /* Hide overflow text */
    white-space: normal; /* Allow normal wrapping */
    height: auto; /* Allow height to adjust */
    vertical-align: top; /* Align text to the top */
  }
  
  .admin-request-table th {
    vertical-align: top; /* Align headers to the top */
  }
  
  .admin-request-edit-button {
    background-color: #C9A236; /* Gold color for the edit button */
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    margin: 5px;
}

.admin-request-edit-button:hover {
    background-color: darkgoldenrod; /* Darker gold on hover */
}

.requests-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px; /* More rounded corners */
    width: 500px; /* Wider modal */
    max-width: 90%; /* Make it responsive */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow for the modal */
    text-align: center; /* Center the content inside the modal */
    animation: fadeIn 0.5s; /* Add a subtle fade-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9); /* Slight zoom effect */
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.requests-modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.requests-modal-actions button:first-of-type {
    background-color: #999; /* Grey for cancel */
    color: white;
}

.requests-modal-actions button:first-of-type:hover {
    background-color: #777; /* Darker grey on hover */
}

.requests-modal-actions button:last-of-type {
    background-color: #C9A236; /* Gold for save */
    color: white;
}

.requests-modal-actions button:last-of-type:hover {
    background-color: darkgoldenrod; /* Darker gold on hover */
}

.requests-modal-content textarea {
    width: 100%; /* Full width */
    min-height: 120px; /* Larger default height */
    padding: 10px; /* Padding for textarea */
    font-size: 14px;
    border: 1px solid #ddd; /* Light border */
    border-radius: 5px;
    resize: none; /* Disable resizing */
    box-sizing: border-box; /* Ensure padding is considered in width */
}

.requests-modal-content select {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.requests-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it stays on top */
}

.requests-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%; /* Adjust width to 90% of the screen width */
    max-width: 500px; /* Set a maximum width for larger screens */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    text-align: center; /* Center align the content */
    animation: fadeIn 0.5s; /* Fade in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9); /* Slight zoom effect on appearance */
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Adjustments for small screens */
@media (max-width: 600px) {
    .requests-modal-content {
        width: 95%; /* Increase width on small screens */
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .requests-modal-content textarea,
    .requests-modal-content select {
        font-size: 14px; /* Slightly smaller font for inputs */
    }
}

/* Modal actions - buttons */
.requests-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap; /* Allow buttons to stack on smaller screens */
}

.requests-modal-actions button {
    width: 48%; /* Buttons take up half the width */
    padding: 10px;
    margin: 5px 0; /* Margin for spacing between buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.requests-modal-actions button:first-of-type {
    background-color: #999;
    color: white;
}

.requests-modal-actions button:last-of-type {
    background-color: #C9A236;
    color: white;
}

.requests-modal-actions button:hover {
    background-color: #555; /* Darker shade on hover */
}

/* Overlay */
.requests-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Textarea */
.requests-modal-content textarea {
    width: 100%; /* Full width for textarea */
    min-height: 120px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
}

/* Dropdown */
.requests-modal-content select {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.admin-request-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.admin-request-filter input {
  width: 45%;
  padding: 8px;
}

.admin-request-filter select {
  width: 45%;
  padding: 8px;
}
