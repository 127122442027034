/* CSS Reset */
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Remove text decoration (links) */
a {
    text-decoration: none;
}

/* Remove list style */
ul, ol {
    list-style: none;
}

/* Reset button styles */
button,
select,
textarea {
    border: none;
    outline: none;
    background: none;
    font: inherit;
    color: inherit;
}

/* Reset form elements */
textarea {
    -webkit-appearance: none; /* Remove input styles in iOS */
    appearance: none;
    background-color: transparent;
}


/* Remove default styling from select and option elements */
select {
  -webkit-appearance: none;  /* Removes default appearance in Safari */
  -moz-appearance: none;     /* Firefox */
  appearance: none;          /* Standard for other browsers */
  background: transparent;   /* Remove default background */
  border: 1px solid #ccc;    /* Add custom border */
  padding: 10px;             /* Adjust padding */
  font-size: inherit;        /* Inherit font size */
  width: 100%;               /* Ensure it fills the container */
}

/* Optional: Remove default arrow in Safari */
select::-webkit-appearance {
  border-radius: 0;
}

/* Custom styles for the dropdown items */
option {
  background: transparent;  /* Remove default option background */
  color: inherit;            /* Inherit font color */
}

.shop-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-height: 52.5vh;
}
.shop-products-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 52.5vh;
}

.shop-title {
  text-align: center;
  margin-bottom: 20px;
  color: #C9A236;
}

.shop-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
  .gridButton{
    flex:4;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  
  .shop-grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 60px; /* Space between items */
    padding-bottom: 20px;
  }


  
  .shop-sidebar {
    
    flex:1;
    border: 1px solid lightgray; /* Optional: add a border */
    padding: 0;
  }
  .category-list,
.heading-item {
    margin: 0; /* Remove margin */
}

  .category-list {
    list-style: none;
    padding: 0;
  }
  .heading-item {
    cursor: pointer;
    padding: 20px;
    font-weight: bold;
    background-color: #F5F5F5;
    letter-spacing: 0.2mm;
    margin-bottom: 10px;
  }
  .category-item {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
  }

  .category-dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 15vw;
  }
  .selected-category-item {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: #C9A236;
  }

  .selected-category-item:hover {
    text-decoration: underline; /* Change background on hover */
    text-decoration-color: #C9A236;
    text-underline-offset: 0.3em;
  }

  .clear-item{
    cursor: pointer;
    padding: 10px 20px;
    font-size: 15px;
    text-decoration: underline;
    text-decoration-color: black;
    text-underline-offset: 0.2em;
    font-weight: bold;
  }
  
  .category-item:hover {
    text-decoration: underline; /* Change background on hover */
    text-decoration-color: #C9A236;
    text-underline-offset: 0.3em;
  }
  
  .shop-content {
    flex: 1; /* Allow content to fill remaining space */
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  
  .load-more-button {
    border: 1px solid black; /* Border color */
    border-radius: 25px; /* Rounded corners */
    text-align: center; /* Center the text */
    font-weight: bold; /* Correct property for bold text */
    font-size: large;
    background-color: white; /* Background color */
    color: black; /* Text color */
    padding: 10px 20px; /* Padding for better click area */
    cursor: pointer; /* Cursor changes to pointer on hover */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
    min-width: 20%;
    margin: 20px auto;
}

.load-more-button:hover {
    background-color: black; /* Change background on hover */
    color: white; /* Change text color on hover */
}
.product-count{
  margin: 0;
  text-align: center;
  font-weight:bold;
  font-size: small;
}
.product-count-top{
  margin: 0;
  text-align: center;
  font-weight:bold;
  font-size: small;
}

.filter-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}
.custom-divider {
  height: 0.5px;
  background-color: lightgrey; /* Color of the divider */
  margin-bottom: 10px; /* Spacing above and below the divider */
}

.shop-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
}

.category-dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 5px;
  max-width: 20vw; /* Updated max-width */
  margin-left: 10px; /* Add space between text and dropdown */
}


.labelSpan {
  display: flex;
  flex-direction: row;
  flex:5;
  align-items: center;
}

@media (min-width: 1200px) {
  .shop-grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 60px; /* Space between items */
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .shop-grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr); /* 4 columns */
    gap: 60px; /* Space between items */
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .shop-grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr); /* 4 columns */
    gap: 60px; /* Space between items */
  }
  .product-count-top{
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .shop-grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr); /* 4 columns */
    gap: 40px; /* Space between items */
  }
  .labelSpan {
    justify-content: space-between;
  }
  .product-count-top{
    display: none;
    visibility: hidden;
    flex:0;
  }
  .category-dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 43vw; /* Updated max-width */
    margin-left: 10px; /* Add space between text and dropdown */
  }
}

