.modify-event-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5ede6; /* Background with theme color */
  padding: 40px;
  min-height: 100vh;
}

.modify-event-form {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 40vw;
  max-width: 400px;
}

.modify-event-formFields {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  min-width: 100%;
}

.modify-event-form-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.modify-event-select {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.modify-event-input, .modify-event-textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modify-event-submit-button {
  width: 100%; /* Full width for the submit button */
  padding: 12px;
  margin-top: 10px;
  background-color: #c9a236; /* Gold color */
  border: none;
  border-radius: 8px; /* Slightly larger radius */
  color: white;
  font-size: 16px; /* Consistent font size */
  font-weight: bold; /* Emphasize text */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.modify-event-submit-button:hover {
  background-color: #f5ede6; /* Light background on hover */
  color: #333; /* Darker text on hover */
}

.remove-assigned-product-button {
  padding: 6px 12px;
  background-color: #e74c3c; /* Red for remove action */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-assigned-product-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}
.success-message {
  color: green;
  background-color: #e0ffe0; /* Light green background */
  border: 1px solid #b2ffb2; /* Darker green border */
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.modify-event-cancel-button {
  background-color: lightgray; /* Red background for delete */
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}
.modify-event-cancel-button:hover {
  background-color: lightslategray; /* Darker red on hover */
}

.modify-event-delete-button {
  background-color: #d9534f; /* Red background for delete */
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.modify-event-delete-button:hover {
  background-color: #c9302c; /* Darker red on hover */
}

.modify-event-delete-button:active {
  background-color: #ac2925; /* Even darker red when clicked */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.event-image{
  width: 100%;
  margin:auto auto;
}
.event-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.event-modal-buttons {
  margin-top: 20px;
}

.event-modal-buttons button {
  margin: 0 10px;
}

/* Assigned Products Section */
.assigned-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  background-color: #f9f9f9; /* Light background for distinction */
  border: 1px solid #ccc; /* Subtle border for separation */
  border-radius: 5px; /* Rounded corners for a modern look */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.assigned-product:hover {
  background-color: #eaeaea; /* Change background on hover */
}

.assigned-product span {
  font-size: 16px; /* Font size for product label */
  font-weight: 500; /* Medium font weight for emphasis */
  color: #333; /* Dark text color for readability */
}

.unassign-product-button {
  padding: 5px 10px; /* Padding for the button */
  background-color: #c9a236; /* Your specified gold theme color */
  color: white; /* Text color for contrast */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded button corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.unassign-product-button:hover {
  background-color: #b59428; /* Darker shade on hover */
}

.unassign-product-button:focus {
  outline: none; /* Remove default focus outline */
}
