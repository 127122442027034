.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This will make the sides stretch to the same height */
    background-color: white; /* Theme background color */
    padding: 20px;
    margin: auto;
}

.contact-side {
    flex: 1;
    margin: 10px;
    display: flex; /* Add this to make the contents of the side flex */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Distribute space evenly */
}

.contact-right-side {
    flex: 2;
    margin: 10px;
    display: flex; /* Add this to make the contents of the side flex */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Distribute space evenly */
}

/* Keeping the rest of your styles as they are */
.contact-google-map {
    min-height:400px;
    width: 100%;
  padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-google-map h3 {
    color: #C9A236; /* Gold color */
    font-size: 18px;
    margin-bottom: 20px;
}

.contact-info {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h3 {
    color: #C9A236; /* Gold color */
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-info p {
    color: #333;
}

.contact-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.contact-form-title {
    text-align: center;
    margin-bottom: 20px;
    color: #C9A236;
    font-size: 24px;
}

.contact-input, .contact-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.contact-submit-button {
    width: 100%;
    padding: 10px;
    background-color: #C9A236;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.contact-submit-button:hover {
    background-color: #f5ede6;
    color: black;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .contact-side {
    width: 48%; /* Adjust to fit your layout */
    
  }
  .contact-right-side {
    width: 48%; /* Adjust to fit your layout */
    max-width: 50vw;
  }
  
  .contact-google-map {
    position: relative;
    width: 100%;
    height: 60vh; /* Set the height to be 100% of the viewport height */
  }
  
  .contact-google-map iframe {
    width: 100%;
    height: 70%;
    border: 0;
  }
  
  .contact-info {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Softer shadow for a modern look */
    text-align: left; /* Center-align text for a cleaner appearance */
    max-width: 50vw;
    margin: 0 auto; /* Center the container */
}

.field {
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: flex-start; /* Align horizontally to the left */
  }
  
  
.contact-info p {
    color: #333; /* Darker text for better readability */
    font-size: 16px;
    line-height: 1.6; /* Increase line height for readability */
}

.contact-info a {
    color: #C9A236; /* Gold color for links */
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s, text-shadow 0.3s; /* Smooth hover effects */
}

.contact-info a:hover {
    color: #f5ede6; /* Light gold on hover */
    text-shadow: 0 0 10px rgba(201, 162, 54, 0.8); /* Glow effect on hover */
}

.contact-info strong {
    font-size: 16px; /* Emphasize the availability text */
    display: block; /* Break to a new line for clarity */
    margin-bottom: 10px;
}
.branchesContainer{
    display: flex;
    justify-content: space-between;
}
.cairo-branch{
    margin: 5px 0;
    flex:1;
    margin-right:15px;
}
.gouna-branch{
    flex:1.1;
    margin: 5px 0;
    padding-left:15px;
    border-left: 1px solid lightgrey;
}

  /* Media query for small screens (e.g., phones) */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Change flex direction to column */
        margin: 0 5%; /* Adjust margin to make it more responsive */
    }

    .contact-side {
        width: 100%; /* Ensure the side takes up full width */
        max-width: 100%;
        margin: 0; /* Remove margin to allow full width */
    }
    .contact-right-side {
        width: 100%; /* Ensure the side takes up full width */
        min-width: 100%;
        margin: 0; /* Remove margin to allow full width */
        flex:1;
    }
    .contact-info {
        max-width: 100%;
    }

    .contact-google-map {
        height: 50vh; /* Adjust the height for small screens */
    }

    .contact-google-map iframe {
        height: 100%; /* Ensure iframe takes full height */
    }
    .branchesContainer{
        display: flex;
        flex-direction: column;
    }
    .gouna-branch{
        flex:1.1;
        margin: 5px 0;
        padding-top:15px;
        border-top: 1px solid lightgrey;
        border-left: none;
        padding-left:0
    }
}