/* AdminDashboard.css */
.dashboard {
    display: flex;
    flex-direction: row;
}

.admin-navbar {
    flex: 1;
    background-color: #c9a236;
 min-height: 100vh;
    transition: width 0.3s, opacity 0.3s; /* Smooth transition */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px;
}

.admin-navbar.collapsed {
    flex: 0; /* Collapse the navbar */
    opacity: 1; /* Keep visible when collapsed */
    padding: 0; /* Remove padding when collapsed */
    min-width: 50px; /* Minimum width for the collapsed state */
    transition: width 0.3s, opacity 0.3s; /* Smooth transition when collapsed */
    justify-content: space-between; /* Space elements correctly */
}

.admin-navbar-header {
    display: flex; /* Flexbox to align items in the header */
    justify-content: space-between; /* Space between title and arrow */
    padding: 20px;
    font-size: large;
    font-weight: bold;
    letter-spacing: 0.5mm;
    min-width: 100%;
    text-align: left;
    font-style: italic;
    color: white; /* Ensure header text is white */
}

.arrow-toggle {
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    color: white; /* Color to match your navbar */
}

.arrow-toggle:hover {
    color: #555; /* Change color on hover if desired */
}

.admin-navbar ul {
    list-style: none; /* Remove bullet points */
    padding: 0;
    width: 100%;
    text-align: left;
    flex: 1; /* Allow the list to take available space */
}

.admin-navbar li {
    text-align: left;
    width: 100%;
    padding: 25px 15px;
    cursor: pointer;
    display: flex; /* Enable flexbox for icon alignment */
    align-items: center; /* Center icons vertically */
    color: white; /* Set initial font color to white */
}

.admin-navbar li:hover {
    color: black;
    background-color: #f5ede6;    
}

#dropdown li {
    padding-left: 50px;
}

/* AdminDashboard.css */
.logout-container {
    min-width: 100%;
    margin-top: auto; /* Push logout to the bottom */
}

.logout {
    padding: 25px 15px;
    min-width: 100%;
    cursor: pointer;
    font-size: large;
    color: white; /* Set initial font color to white */
    display: flex; /* Enable flexbox for icon alignment */
    
}

.logout-collapsed {
    padding: 25px 15px;
    cursor: pointer;
    font-size: large;
    color: white; /* Set initial font color to white */
    display: flex; /* Enable flexbox for icon alignment */
    align-items: center; /* Center icons vertically */
}
/* Add margin to the icon */
.logout svg, .logout-collapsed svg {
    margin-right: 15px; /* Adjust the value as needed for spacing */
}

.logout-collapsed svg {
    margin-right: 2px; /* Adjust the value as needed for spacing */
}

.logout:hover {
    background-color: #555; /* Same hover effect for consistency */
    color: red; /* Change font color to red on hover */
}

.logout-collapsed:hover {
    color: red; /* Change font color to red on hover */
}

.content {
    flex: 4;
}
