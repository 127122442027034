/* Navbar container */
/* Apply the font to the entire body */
body {
  font-family: 'Montserrat', sans-serif; /* Change to your chosen font */
}

.navContainer{
  position: sticky; /* Make the navbar sticky */
  top:0;
  z-index: 950;

}

.navbar {
  display: flex; /* Keep as flex for layout */
  justify-content: space-between;
  align-items: center;
  padding: 25px 10vw 25px 7vw;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 80px; /* Define a fixed height for the navbar */
  position: sticky; /* Make the navbar sticky */
}


/* Ensure body has padding to prevent overlap */
body {

}


/* Left Section: Logo */
.navbar-logo img {
  height: auto; /* Set height to auto to maintain aspect ratio */
  max-width: 200px; /* Set a maximum width for the logo */
  width: 100%; /* Allow the logo to take full width of the container */
}


/* Center Section: Links */
.navbar-center-links {
  list-style: none;
  display: flex;
  gap: 4vw;
  padding:0;
  margin:0;
}
.navbar-mobile-links {
  list-style: none;
  padding:0;
  line-height: 2;
  
}
.navbar-center-links li {
  display: inline-block;
  position: relative; /* Position for the underline */
}
.navbar-mobile-links a {
  text-decoration: none;
  color: #333;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 1px;
  font-weight: 600;
  transition: color 0.3s; /* Smooth color transition */
}

.navbar-center-links a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: color 0.3s; /* Smooth color transition */
}

/* Custom underline using pseudo-element */
.navbar-center-links a::after {
  content: '';                          /* Empty content */
  display: block;                       /* Make it a block element */
  height: 2px;                          /* Thickness of the underline */
  background: #c9a236;                 /* Underline color */
  width: 100%;                          /* Full width */
  position: absolute;                   /* Position it absolutely */
  left: 0;                              /* Align to left */
  bottom: -4px;                         /* Space between text and underline */
  transform: scaleX(0);                 /* Start with zero width for scaling */
  transition: transform 0.3s;          /* Smooth transition for scaling */
}

.navbar-mobile-links a::after {
  content: '';                          /* Empty content */
  display: block;                       /* Make it a block element */
  height: 2px;                          /* Thickness of the underline */
  background: #c9a236;                 /* Underline color */
  width: 100%;                          /* Full width */
  position: absolute;                   /* Position it absolutely */
  left: 0;                              /* Align to left */
  bottom: -4px;                         /* Space between text and underline */
  transform: scaleX(0);                 /* Start with zero width for scaling */
  transition: transform 0.3s;          /* Smooth transition for scaling */
}

.navbar-mobile-links a:hover {
  color: #c9a236;  /* Change to desired font color on hover */
}

/* Navbar link hover effect: color and underline */
.navbar-center-links a:hover {
  color: #c9a236;  /* Change to desired font color on hover */
}
.navbar-mobile-links a:hover::after {
  transform: scaleX(1); /* Scale to full width on hover */
}
/* Show underline on hover */
.navbar-center-links a:hover::after {
  transform: scaleX(1); /* Scale to full width on hover */
}

/* Right Section: Icons */
.navbar-icons {
  display: flex;
  gap: 2vw;
}

.icon {
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

/* Navbar on Scroll: Add box shadow */
.navbar.scroll-active {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.icon {
  font-size: 1.5rem; /* Adjust the size as needed */
  padding: 5px; /* Padding around the icon */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  cursor: pointer; /* Cursor pointer on hover */
}

/* Change icon styles on hover */
.icon:hover {
  color:#c9a236; ; /* Remove fill color on hover */ /* Change icon color to black on hover */
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar-center-links {
    display: none; /* Hide the links on small screens */
  }

  .navbar-icons {
    gap: 10px; /* Reduce the gap for icons on smaller screens */
  }
}

/* Existing Navbar CSS */

/* Media Query for Responsive Navbar */
@media (max-width: 768px) {
  .icon {
    font-size: 1.0rem;
    color: #333;
    cursor: pointer;
    padding:0;
    
  }
  .navbar-center-links {
    display: none; /* Hide the links by default on small screens */
    flex-direction: column; /* Stack links vertically when shown */
    position: absolute; /* Position the dropdown */
    top: 70%; /* Position it below the navbar */
    left: 53%; /* Align to the left */
    background-color: white; /* Background color for the dropdown */
    width: 45%; /* Full width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
  }

  .navbar-center-links.active {
    display: flex; /* Show the links when active */
  }

  .hamburger {
    display: block; /* Show hamburger menu on small screens */
    cursor: pointer; /* Change cursor to pointer */
  }

  .navbar-icons {
    display: none; /* Hide icons on small screens */
  }
  .navbar-logo img {
    height: auto; /* Set height to auto to maintain aspect ratio */
    max-width: 180px; /* Set a maximum width for the logo */
    width: 100%; /* Allow the logo to take full width of the container */
  }
  
}

/* For larger screens, hide the hamburger icon */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide hamburger menu on larger screens */
  }
}

/* searchResults.css */

.search-results {
  margin-top: 10px;
  max-height: 70vh;
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c9a236 #f1f1f1; /* For Firefox */
}

.search-results h3 {
  margin: 0; /* Adjust header margin if necessary */
}

.search-results ul {
  list-style-type: none;
  padding: 0;
}

.search-results li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0; /* Divider between results */
  padding: 10px 0;
  transition: background-color 0.3s;
}

.search-results li:hover {
  background-color: #f5f5f5; /* Background color on hover */
}

.search-results img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 10px;
}

.search-product-results {
  display: flex;
  flex-direction: column;
}

/* searchComponent.css */
.search-result-item {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Space between image and text */
}

.search-result-image img {
  width: 100px; /* Adjust image size as needed */
  height: 100px; /* Make images uniform */
  object-fit: cover; /* Ensures image maintains aspect ratio without stretching */
  border-radius: 5px; /* Optional: add rounded corners */
}

.search-product-results {
  flex-grow: 1; /* Take up remaining space for the text */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the text */
}

.product-name {
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.product-description {
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensures text doesn't overflow */
  margin: 5px 0 0 0; /* Optional: add margin to separate description from the product name */
  text-align: center;
}


.search-component {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  min-width: 100%;
  max-width: 100%;
}

.search-header img {
  max-width: 150px;
}
#logoForSearch{
    width: 100px;
}

#searchProductsMenu {
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 35px;
  height: 40px;
  padding: 0 15px;
  margin: 0;
  margin-left: 15px;
}

.search-header span {
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
  color: #c9a236;
}


/* Responsive styles */
@media (max-width: 1200px) {
  .search-results {
    padding: 0 100px; /* Reduce padding for medium screens */
  }
}

@media (max-width: 992px) {
  .search-results {
    padding: 0 50px; /* Further reduce padding for smaller screens */
  }
}

@media (max-width: 768px) {
  .search-results {

    padding: 0; /* Minimal padding for mobile screens */
  }
  
.search-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 50px;
  min-width: 100%;
  max-width: 100%;
}

  .search-results li {
    flex-direction: row; /* Stack image and text vertically on small screens */
    align-items: flex-start; /* Align items to the start */
  }

  .search-results img {
    margin-right: 10px; /* Remove right margin for images */
    margin-bottom: 10px; /* Add bottom margin for spacing */
    max-width: 100px;
  max-height: 100px;
  }
}
.navbar .navbar-center-links li {
  position: relative; /* Ensure the dropdown is positioned relative to the parent */
}

.dropdown-menu {
  display: none; /* Hidden by default */
  position: absolute; /* Positioned relative to the parent li */
  top: 100%; /* Align below the parent */
  left: 0; /* Align to the left of the parent */
  background-color: white; /* Background color of dropdown */
  border: 1px solid #ccc; /* Border for the dropdown */
  width: 200px; /* Width of the dropdown */
  z-index: 10; /* Stack above other elements */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  border-radius: 4px; /* Rounded corners */
}

.navbar-center-links li:hover .dropdown-menu {
  display: block; /* Show the dropdown on hover */
}

.dropdown-menu li {
  padding: 10px; /* Padding for dropdown items */
  cursor: pointer; /* Cursor changes to pointer on hover */
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent wrapping of text */
}

.dropdown-menu li:hover {
  background-color: #f5f5f5; /* Background color on hover */
}

.dropdown-menu {
  display: flex; /* Flexbox layout for dropdown */
  padding: 10px; /* Add padding to the dropdown */
  background-color: white; /* Background color for the dropdown */
  border: 1px solid #ccc; /* Border around the dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}
.dropdown-item-main {
  padding: 10px; /* Padding for dropdown items */
  cursor: pointer; /* Change cursor to pointer on hover */
  white-space: nowrap; /* Prevent text wrapping */
}
.dropdown-item {
   /* Padding for dropdown items */
  padding: 10px;
  padding-left: 35px;
  cursor: pointer; /* Change cursor to pointer on hover */
  white-space: nowrap; /* Prevent text wrapping */
}
.dropdown-label {
  padding: 10px; /* Padding for dropdown items */
  white-space: nowrap; /* Prevent text wrapping */
  color:gray;
  font-weight: lighter;
  font-size: small;
}
.dropdown-item:hover {
  background-color: #f5f5f5; /* Background color on hover */
}

.dropdown-item-main:hover {
  background-color: #f5f5f5; /* Background color on hover */
}

.dropdown-menu {
  display: none; /* Hidden by default */
  position: absolute; /* Positioned relative to the parent li */
  top: 100%; /* Align below the parent */
  left: 0; /* Align to the left of the parent */
  background-color: white; /* Background color of dropdown */
  border: 1px solid #ccc; /* Border for the dropdown */
  width: 200px; /* Width of the dropdown */
  z-index: 10; /* Stack above other elements */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  border-radius: 4px; /* Rounded corners */
  display: flex; /* Enable flexbox for layout */
  flex-direction: column; /* Stack items in a column */
}

.navbar-center-links li:hover .dropdown-menu {
  display: flex; /* Show the dropdown on hover */
}

/* Sidebar styling */
.search-sidebar {
  position: fixed;
  top: 0;
  right: 0; /* Move the sidebar to the right side */
  min-width: 30vw; /* Sidebar width */
  max-width: 30vw; /* Sidebar width */
  height: 100vh; /* Full height */
  background-color: #fff;
  z-index: 5000; /* High z-index for visibility */
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow on the left */
  padding: 50px 20px ;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
}

/* Search header styling */
.search-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  min-width: 100%;
  max-width: 100%;
}

.search-icon {
  font-size: 24px;
}

#searchProductsMenu {
  width: 60%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  flex:8;
}

.close-btn {
  font-size: 18px;
  cursor: pointer;
  flex:1;
}

/* Search results grid */
.search-results {
  margin-top: 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.search-results h3 {
  margin-bottom: 20px;
}

.search-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row */
  gap: 15px;
}

.search-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s;
  align-items: center;
  justify-content: center;
}

.search-item:hover {
  background-color: #f5f5f5;
}

.search-item-image img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.search-item-text {
  margin-top: 10px;
}

.product-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.product-description {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .search-sidebar {
    width: 100vw; /* Full width on small screens */
    min-width: 100vw; /* Ensure it takes full width */
    max-width: 100vw; /* Ensure it takes full width */
    padding: 50px 20px; /* Adjust padding for small screens */
    min-height: 100vh;
    overflow: hidden;
  }

.navbar {
  display: flex;
  align-items: center; /* Vertically center all items */
  justify-content: space-between; /* Space between hamburger and logo */
  position: relative; /* Needed for centering the logo using absolute positioning */
}
/* 
.navbar-logo {
  position: absolute; /* Center logo using absolute positioning 
  left: 50%; /* Move it to the horizontal center 
  transform: translateX(-50%); /* Adjust to align the center of the logo 
} */

/* .hamburger {
  margin-left: auto; /* Ensure it stays on the rightmost side 
  cursor: pointer; /* Make it clickable 
  font-size: 1.1em;
}
.icon{
  padding: 0;
  margin-right: 20px;
} */


  #searchProductsMenu {
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    margin: 0;
  }
  .search-header {
    display: flex;
    align-items: right; /* Align items vertically */
    height: 60px;
    min-width: 100%;
    max-width: 100%;
  }
  .close-btn {
    margin-left: auto;
    cursor: pointer;
    font-size: 1.5rem; /* Adjust font size for visibility */
    color: #333; /* Button color */
    background: none; /* Remove default button styles */
    border: none; /* Remove default border */
    /* transform: translateX(90%); Optional: Reset any unwanted transformations */
  }
  
  
  .close-btn:hover {
    color: #C9A236; /* Change color on hover (optional) */
  }
  .mobile-dropdown-link {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align arrow on the right */
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 0px;
    line-height: 2;
  }
  
  .dropdown-arrow {
    font-size: 18px;
    color: #666; /* Adjust icon color */
    margin-left: 5px; /* Add spacing between text and icon */
  }
  
  .mobile-dropdown-menu {
  list-style-type: none; /* Remove bullet points */
  margin: 0;
  padding: 0;
}

.mobile-dropdown-item-main {
  padding: 10px 0px 0px 20px; /* Add padding for spacing */
  cursor: pointer;
}
.mobile-dropdown-item {
  padding: 10px 0px 0px 40px; /* Add padding for spacing */
  cursor: pointer;
}
.mobile-dropdown-item:hover {
  background-color: #f0f0f0; /* Optional: Add a hover effect for better UX */
}

.mobile-dropdown-menu {
  list-style-type: none; /* Remove bullet points */
  margin: 0;
  padding: 0;
}
.mobile-dropdown-item:hover {
  background-color: #f0f0f0; /* Optional: Add a hover effect for better UX */
}

}
