.card {
    position: relative;
    width: 25vw;
    min-width: 200px;
    max-width: 200px;
    height: 40vh;
    text-align: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
  }
  
  .icon-container {
    position: relative;
    background-color: #f5f5f5;
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .categoryIcon {
    height: 50%;
    max-height: 100px;
    fill: #C9A236;
    margin: auto;
    transition: fill 0.3s ease;
  }
  
  .card-title {
    height: 20%;
    font-size: 1rem;
    color: #333;
    text-decoration: underline;
    text-decoration-color: #C9A236;
    text-underline-offset: 0.3em;
    letter-spacing: 0.05rem;
    transition: color 0.3s ease;
  }
  
  .card:hover .card-title {
    color: #C9A236;
  }
  

@keyframes draw-border {
    0% {
        transform: scale(0); /* Start from scale 0 */
        opacity: 1; /* Make visible from the start */
    }
    25% {
        transform: scaleX(1); /* Draw top border */
    }
    50% {
        transform: scaleY(1); /* Draw left border */
    }
    75% {
        transform: scaleY(1); /* Draw right border */
        transform-origin: bottom right; /* Change origin for scaling */
    }
    100% {
        transform: scale(1); /* Complete the rectangle */
    }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .card {
        min-width: 40vw; /* Increase width for smaller screens */
        max-width: 40vw; /* Remove max width */
        height: 35vh; /* Allow height to adjust based on content */
    }

    .categoryIcon {
        max-height: 80px; /* Smaller max height for icons on smaller screens */
    }

    .card-title {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .card {
        min-width: 40vw; /* Full width for very small screens */
        max-width: 40vw;
        margin: 5px; /* Reduced margin */
    }

    .categoryIcon {
        max-height: 60px; /* Further reduce icon height */
    }

    .card-title {
        font-size: 0.8rem; /* Further adjust font size */
    }
}
