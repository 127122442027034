.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5ede6; /* Background with theme color */
}

.admin-login-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.admin-login-title {
    text-align: center;
    margin-bottom: 20px;
    color: #c9a236; /* Using the gold color from the theme */
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.admin-login-btn {
    width: 100%;
    padding: 10px;
    background-color: #c9a236;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.admin-login-btn:hover {
    background-color: #f5ede6;
    color: black;
}

.error-message {
    color: #f44336; /* Red color for error message */
    margin-bottom: 10px;
    text-align: center;
}
