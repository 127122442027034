.product-slider {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 40px 70px;
    max-width: 100%; /* Adjust to the desired max width */
  }
  
  
  .product-cards {
    display: flex;
    scroll-behavior: smooth; /* Smooth scroll effect */
    gap: 40px;
    width: 80%; /* Adjust width as needed */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical overflow */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .trending-product-width{
    flex: 1;
  }
  
  .arrow-button, .trending-arrow-button {
    background-color: white; /* White background */
    border: none;
    border-radius: 50%;
    width: 40px; /* Adjust as necessary */
    height: 40px; /* Adjust as necessary */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px; /* Spacing around the buttons */
    font-size: 40px; /* Adjust the size of the arrow */
    color: black; /* Black arrow color */
  }
  
  .arrow-button:disabled, .trending-arrow-button:disabled{
    color: lightgray; /* Grey background for disabled state */
    cursor: auto; /* Change cursor style */
  }

  .trending-title { 
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-decoration: underline; /* Add underline */
    text-decoration-color: #C9A236; /* Set the underline color */
    text-underline-offset: 0.3em; /* Adjust spacing between text and underline */
    letter-spacing: 0.05rem; /* Add letter spacing */
}
  
.trending-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.trending-button {
  flex: 1; /* Ensure buttons grow equally and take up equal space */
  min-width: 0; /* Allow buttons to shrink properly */
  background-color: lightgray; /* Grey background for non-selected buttons */
  color: black; /* Black text */
  border: 1px solid transparent; /* Transparent border by default */
  padding: 10px 20px; /* Padding for button size */
  font-size: 1rem; /* Adjust font size */
  cursor: pointer; /* Pointer cursor */
  margin: 0 10px; /* Margin between buttons */
  transition: all 0.3s ease; /* Transition for hover effects */
  display: inline-flex; /* Align the content properly */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  text-align: center; /* Center text in case of longer words */
}



.trending-button.active {
    background-color: white; /* White background for selected button */
    color: #C9A236; /* Gold color for text */
    border: 2px solid #C9A236; /* Gold border */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .trending-product-width {
    flex: 0 0 70%; /* Makes each product 50% of container width on smaller screens */
  }
  .product-slider{
    padding: 40px 40px;
    gap: 35px;
  }
  .product-cards {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .trending-product-width {
    flex: 0 0 100%; /* Each product takes full width on very small screens */
  }
  .product-slider{
    padding: 40px 0px;
    gap: 15px;
  }
  .product-cards {
    min-width: 70%;
    gap: 10px;
  }
  .trending-arrow-button{
    margin:0;
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .trending-button{
    max-width: 31vw;
  }
  .product-cards {
    display: flex;
    width: 100%; /* Take full width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 0; /* No gaps between products */
  }

  .trending-product-width {
    flex: 0 0 100%; /* Each product takes full width */
    width: 100%; /* Ensure full width */
  }
}
