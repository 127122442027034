.overlay {
    position: fixed; /* Fixed positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
    display: flex; /* Flexbox to center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 999; /* Ensure it's above other content */
}
/* popup.css */
.popup {
    background-color: white;
    padding: 20px 5px;
    border: 1px solid #C9A236;
    z-index: 1000; /* Ensure the popup is on top */
    max-width: 70vw; /* Set the width */
    max-height: 70vh; /* Set the height */
    min-width: 70vw; /* Set the width */
    min-height: 70vh; /* Set the height */
    display: flex;
    flex-direction: row;
}


.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #333;
}


.image-container {
    flex: 1; /* Take 50% of width */
    justify-content: center;
    align-items: center;
    height: 100%;
}

.popup-image {
    max-height: 65vh; /* Responsive image */
    max-width: 80%;
    object-fit: contain;
}

.details-container {
    flex: 1; /* Take 50% of width */
    padding: 20px; /* Padding around details */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.popup-title {
    font-weight: bold; /* Bold title */
    font-size: 1.5rem; /* Large title */
    margin: 5px 0; /* Remove default margin */
}

.popup-category {
    font-size: 0.7rem; /* Smaller category */
    margin-top: 5px; /* Remove default margin */
    color: lightgray
}

.divider {
    margin: 5px 0; /* Margin for the divider */
}

.popup-description {
    flex: 1; /* Take available space */
    font-size: 1.1rem; /* Font size */
    line-height: 1.3;
    letter-spacing: 0.3mm;
    margin: 10px 0; /* Margin for description */
    overflow-y: auto;
}

.request-price-button {
    background-color: #C9A236; /* Button color */
    color: white; /* Button text color */
    padding: 10px; /* Button padding */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    font-size: 1rem; /* Font size */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.request-price-button:hover {
    background-color: #A68B25; /* Darker color on hover */
}

.request-price-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px; /* Add space above the form */
}

.image-slider {
    flex:1;
    min-width: 50%;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-between; /* Center horizontally */
  }
  
  .slider-button {
    all: unset;
    background: none; /* Remove default background */
    border: none; /* Remove border */
    font-size: 24px; /* Size of arrows */
    cursor: pointer; /* Pointer cursor for buttons */
    margin: 0 10px; /* Space between buttons and image */
  }
  

  
.request-price-form input,
.request-price-form textarea {
    margin-bottom: 20px; /* Space between fields */
    padding: 10px; /* Padding inside fields */
    border: 1px solid #ccc; /* Border for fields */
    border-radius: 5px; /* Rounded corners */
}

.request-price-form textarea {
    resize: none; /* Prevent resizing */
    min-height: 62px;
}

.request-price-form button {
    background-color: #C9A236; /* Button color */
    color: white; /* Button text color */
    padding: 10px; /* Button padding */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    font-size: 1rem; /* Font size */
    border-radius: 5px; /* Rounded corners */
}

.form-overlay {
    position: fixed; /* Fixed positioning */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex; /* Flexbox to center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1001; /* Ensure it's above the product popup */
}

.form-popup {
    background-color: white;
    padding: 20px;
    border: 1px solid #C9A236;
    min-height: auto;
    max-height: 80%;
    width: 40%; /* Responsive width */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
    position: relative; /* Relative for close button positioning */
    overflow: auto; /* Allow scrolling if content exceeds height */
}

.form-heading {
    font-size: 1.5rem; /* Size for the heading */
    margin-bottom: 20px; /* Space below the heading */
    text-align: center; /* Center the heading text */
    color: #C9A236;
}
.request-price-form label {
    margin-bottom: 5px; /* Space between label and input */
    font-weight: bold; /* Make the label bold */
    font-size: 0.8rem; /* Font size for labels */
    display: block; /* Ensure labels take a full line */
    text-align: left;
}


.confirmation-message {
    position: fixed; /* Fixed positioning for the message */
    font-weight: bold;
    letter-spacing: 0.2;
    top: 150px; /* Position at the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust centering */
    color: #6b8a66; /* Confirmation text color */
    padding: 10px; /* Padding for the message */
    border-radius: 5px; /* Rounded corners */
    z-index: 20000; /* Ensure it's above everything */
}

@media (max-width: 991px) and (min-width: 768px) {
    .form-popup {
        background-color: white;
        padding: 20px;
        border: 1px solid #C9A236;
        height: 60%;
        width: 50%; /* Responsive width */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
        position: relative; /* Relative for close button positioning */
        overflow: auto; /* Allow scrolling if content exceeds height */
    }
    .popup {
        max-height: 70vh;
      }
  }

@media (max-width: 950px) {
    .form-popup {
        background-color: white;
        padding: 20px;
        border: 1px solid #C9A236;
        max-height: 75%;
        min-width: 80%; /* Responsive width */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
        position: relative; /* Relative for close button positioning */
        overflow: auto; /* Allow scrolling if content exceeds height */
    }
    .popup {
        background-color: white;
        padding: 20px 5px;
        border: 1px solid #C9A236;
        z-index: 1000; /* Ensure the popup is on top */
        max-width: 70vw; /* Set the width */
        max-height: 85vh; /* Set the height */
        min-width: 70vw; /* Set the width */
        min-height: 70vh; /* Set the height */
        display: flex;
        flex-direction: row;
    }
    .popup-description {
        display: -webkit-box;
        -webkit-line-clamp: 6; /* Limit to 3 lines */
        -webkit-box-orient: vertical; /* Set box to vertical orientation */
        overflow: hidden; /* Hide content that exceeds the 3 lines */
        text-overflow: ellipsis; /* Add ellipsis (…) if the content overflows */
        max-height: calc(3 * 1.2em); /* Adjust height for 3 lines (you can tweak the multiplier) */
        line-height: 1.2em; /* Adjust line height to suit your design */
        white-space: normal; /* Allow text to wrap normally */
        word-wrap: break-word; /* Ensure long words break correctly */
      }
      
      .popup-description:hover {
        overflow-y: auto; /* Enable scrolling when hovered over */
        max-height: none; /* Allow the description to expand fully when hovered */
        cursor: pointer; /* Show pointer cursor to indicate scrollable area */
      }
  }

  @media (max-width: 950px) {
    .popup {
      flex-direction: column; /* Change to column for smaller screens */
      min-width: 90%; /* Increase width to take more space */
      max-width: 100%; /* Allow full width on small screens */
      padding: 15px; /* Adjust padding */
    }
  
    .popup-image{
        width: 100%;
    }

    .image-slider {
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        margin:auto; /* Make it take full width */
        max-height: 70%;
        max-width: 80%;
    }
    
    .popup h2 {
      font-size: 1.5rem; /* Adjust font size for titles */
      margin-bottom: 10px; /* Space below title */
    }
  
    .popup p {
      font-size: 1rem; /* Adjust font size for description */
      max-height: 100px;
      overflow: auto;
    }
}
@media (max-width: 1250px) and (min-width: 768px) {
    .image-slider {
        align-items: center;
        justify-content: center;
        margin: auto;
        max-height: 50%;
        max-width: 80%;
    }
    .popup-image {
        max-height: 50vh;
        object-fit: contain;
    }
    .details-container{
        padding:0;
    }
  }

  @media (max-width: 950px) {
    .image-slider {
        align-items: center;
        justify-content: center;
        margin: auto;
        max-height: 100%;
        min-width: auto;
        max-width: 90%;
    }
    .popup-image {
        max-height: 35vh;
        max-width: 100%;
        object-fit: contain;
        padding: 0;
        margin:0;
    }
    .details-container{
        padding:0;
    }
    .popup p {
        font-size: 1rem; /* Adjust font size for description */
        max-height: 220px;
        overflow: auto;
      }
      .slider-button{
        text-decoration: none;
      }
  }